<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <b>{{ $t('generic.lang_vmpLocale') }}</b>
        <br>{{ $t('generic.lang_germany') }}
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <v-text-field v-model="ehastraNumber" dense outlined type="number" :label="$t('settings.lang_ehastraNumber')"></v-text-field>

        <v-autocomplete
            :items="itemgroups"
            v-model="waregroup"
            outlined
            dense
            item-text="name"
            item-value="id"
            :label="$t('settings.lang_goodsGroupForVmpSales')"
        ></v-autocomplete>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <v-btn class="float-right" @click="save" :disabled="loading || ehastraNumber.length === 0 || waregroup === 0"
               :loading="loading" color="success">{{ $t('generic.lang_save') }}
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {mapState} from "vuex";
import {ENDPOINTS} from "@/config";

export default {
  name: "VMPSettingsMain",

  data() {
    return {
      loading: false,

      ehastraNumber: "",
      waregroup: 0,
      itemgroups: []
    }
  },

  async mounted() {
    this.itemgroups = await this.$store.dispatch("itemgroups/getItemgroups");

    this.getSettings();
  },

  methods: {
    getSettings() {
      this.loading = true;

      this.axios.post(ENDPOINTS.SETTINGS.BASIC.SYSTEMSETTINGS.VMP.MAINSETTINGS.GET).then((res) => {
        if (res.data.success) {
          this.ehastraNumber = res.data.ehastraNumber;
          this.waregroup = res.data.waregroup;
        }
      }).finally(() => {
        this.loading = false;
      });
    },
    save() {
      this.loading = true;

      this.axios.post(ENDPOINTS.SETTINGS.BASIC.SYSTEMSETTINGS.VMP.MAINSETTINGS.UPDATE, {
        ehastraNumber: this.ehastraNumber,
        waregroup: this.waregroup
      }).then((res) => {

      }).finally(() => {
        this.loading = false;
      });
    }
  }
}
</script>