<template>
    <div :class="[this.$vuetify.theme.dark? 'dark-bg' : '']" class="card main-card">
        <div :class="[this.$vuetify.theme.dark? 'white--text' : '', 'card-title card-header-tab card-header transparent']">
            <div class="card-header-title font-size-lg text-capitalize font-weight-normal">
                {{ $t('generic.lang_vmpsettings') }}
            </div>
        </div>

        <v-divider class="pa-0 ma-0"/>

        <v-tabs>
            <v-tab>{{ $t('generic.lang_set_overall') }}</v-tab>
            <v-tab>{{ $t('generic.lang_transfer') }}</v-tab>

            <v-tab-item>
                <VMPSettingsMain></VMPSettingsMain>
            </v-tab-item>

            <v-tab-item>
                <VMPSettingsFTP></VMPSettingsFTP>
            </v-tab-item>
        </v-tabs>

        <div id="onScreenKeyboardDiv" v-click-outside="dismissOnScreenKeyboard">
            <vue-touch-keyboard :accept="hideTouchKeyboard" :cancel="hideTouchKeyboard"
                                :defaultKeySet="touchKeyboard.keySet"
                                :input="touchKeyboard.input" :layout="touchKeyboard.layout"
                                :options="touchKeyboard.options" class="internalWidth"
                                id="onScreenKeyboard" v-if="touchKeyboard.visible"/>
        </div>
    </div>
</template>

<script>
import mixin from "../../../mixins/KeyboardMixIns";
import VMPSettingsMain from "@/components/settings/moduleSettings/vmpsettings/VMPSettingsMain";
import VMPSettingsFTP from "@/components/settings/moduleSettings/vmpsettings/VMPSettingsFTP";

export default {
    name: "VMPSettings",

    components: {
        VMPSettingsFTP,
        VMPSettingsMain
    },

    mixins: [mixin],

    data() {
        return {
            tab: 0
        }
    }
}
</script>