<template>
    <div>
        <page-title heading="VMP" subheading="VMP" :icon=icon></page-title>
        <div class="app-main__inner">
        <vmpInterface></vmpInterface>
        </div>
    </div>
</template>

<script>
    import PageTitle from "../../../Layout/Components/PageTitle.vue";
    import vmpInterface from "../../../components/settings/moduleSettings/VMPSettings.vue";

    export default {
        components: {
            PageTitle,
          vmpInterface
        },

        data: () => ({
            icon: 'pe-7s-plane icon-gradient bg-tempting-azure',
        })
    }
</script>