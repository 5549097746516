<template>
    <v-container>
        <v-row>
            <v-col cols="12" sm="12" md="6">
                <v-checkbox :label="$t('generic.lang_uploadAutomatically')" v-model="autoUpload"></v-checkbox>
            </v-col>

            <v-col v-if="autoUpload" cols="12" sm="12" md="6">
                <v-text-field dense outlined :label="$t('generic.lang_timeToUpload')" hint="Format: HH:MM" prefix="HH:MM"></v-text-field>
            </v-col>
        </v-row>

        <v-divider></v-divider>

        <v-row>
            <v-col cols="12" sm="12" md="8">
                <v-text-field v-model="ftpServer" dense outlined :label="$t('generic.lang_ftpServer')"></v-text-field>
            </v-col>
 
            <v-col cols="12" sm="12" md="4">
                <v-text-field v-model="ftpPort" type="number" dense outlined :label="$t('generic.lang_ftpPort')"></v-text-field>
            </v-col>
        </v-row>

        <v-row>
            <v-col cols="12" sm="12" md="6">
                <v-text-field v-model="ftpUser" dense outlined :label="$t('generic.lang_ftpUser')"></v-text-field>
            </v-col>

            <v-col cols="12" sm="12" md="6">
                <v-text-field v-model="ftpPassword" dense outlined :label="$t('generic.lang_ftpPassword')" type="password"></v-text-field>
            </v-col>
        </v-row>

        <v-row>
            <v-col cols="12">
                <v-btn class="float-right" @click="save" :disabled="loading" :loading="loading" color="success">{{ $t('generic.lang_save') }}</v-btn>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import {ENDPOINTS} from "@/config";

export default {
    name: "VMPSettingsFTP",

    data() {
        return {
            loading: false,

            autoUpload: false,
            ftpServer: "",
            ftpPort: 0,
            ftpUser: "",
            ftpPassword: ""
        }
    },

    mounted() {
      this.getSettings();
    },

    methods: {
        getSettings() {
            this.loading = true;

            this.axios.post(ENDPOINTS.SETTINGS.BASIC.SYSTEMSETTINGS.VMP.TRANSFERSETTINGS.GET).then((res) => {
                this.autoUpload = res.data.autoUpload === 1;
                this.ftpServer = res.data.ftpServer;
                this.ftpPort = res.data.ftpPort;
                this.ftpUser = res.data.ftpUser;
                this.ftpPassword = res.data.ftpPassword;
            }).finally(() => {
                this.loading = false;
            })
        },
        save() {
            this.loading = true;

            this.axios.post(ENDPOINTS.SETTINGS.BASIC.SYSTEMSETTINGS.VMP.TRANSFERSETTINGS.UPDATE, {
                autoUpload: this.autoUpload === true ? 1 : 0,
                ftpServer: this.ftpServer,
                ftpPort: this.ftpPort,
                ftpUser: this.ftpUser,
                ftpPassword: this.ftpPassword
            }).then(() => {

            }).finally(() => {
                this.loading = false;
            });
        }
    }
}
</script>